import React from 'react';

const AllIcon = ({ size = 20, color = '#61717E', orientation = 'down' }: { size?: number, color?: string, orientation?: string }) => (
  <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="62" cy="62" r="62" fill="url(#paint0_linear_20222_57685)" />
    <path d="M66.1794 46.22C66.9197 45.5199 67.9048 45.1329 68.9268 45.1406C69.9489 45.1483 70.928 45.5501 71.6575 46.2612C72.387 46.9723 72.8099 47.9371 72.8369 48.952C72.8639 49.9669 72.493 50.9526 71.8023 51.701L50.8347 77.7487C50.4742 78.1345 50.039 78.4441 49.5553 78.6589C49.0715 78.8738 48.5491 78.9896 48.0193 78.9993C47.4895 79.0091 46.9632 78.9126 46.4717 78.7157C45.9803 78.5187 45.5339 78.2254 45.1593 77.8531L31.2544 64.041C30.8672 63.6826 30.5566 63.2504 30.3412 62.7702C30.1258 62.2899 30.01 61.7715 30.0006 61.2459C29.9913 60.7202 30.0886 60.198 30.2868 59.7105C30.4851 59.2231 30.7801 58.7802 31.1544 58.4085C31.5286 58.0367 31.9744 57.7436 32.4652 57.5467C32.956 57.3498 33.4816 57.2531 34.0108 57.2624C34.54 57.2717 35.0619 57.3867 35.5454 57.6007C36.0288 57.8147 36.4639 58.1232 36.8248 58.5079L47.8288 69.4333L66.0743 46.3348C66.107 46.2945 66.1421 46.2562 66.1794 46.22ZM61.3448 73.0507L66.1794 77.8531C66.554 78.2246 67.0001 78.5172 67.491 78.7137C67.9819 78.9101 68.5076 79.0064 69.0368 78.9966C69.566 78.9869 70.0878 78.8714 70.571 78.657C71.0543 78.4426 71.4891 78.1337 71.8496 77.7487L92.8277 51.701C93.2046 51.3314 93.5026 50.8902 93.7039 50.4037C93.9053 49.9172 94.0059 49.3953 93.9997 48.8693C93.9936 48.3434 93.8807 47.824 93.668 47.3423C93.4553 46.8606 93.147 46.4264 92.7616 46.0656C92.3762 45.7049 91.9215 45.425 91.4248 45.2427C90.928 45.0604 90.3994 44.9793 89.8705 45.0045C89.3415 45.0296 88.8231 45.1604 88.3461 45.3889C87.8692 45.6175 87.4435 45.9392 87.0945 46.3348L68.8437 69.4333L66.295 66.8964L61.3395 73.0507H61.3448Z" fill="white" />
    <defs>
      <linearGradient id="paint0_linear_20222_57685" x1="62" y1="0" x2="62" y2="124" gradientUnits="userSpaceOnUse">
        <stop stopColor="#37B06F" />
        <stop offset="1" stopColor="#199251" />
      </linearGradient>
    </defs>
  </svg>

);
export default AllIcon;