import { dashToNormalCase } from '../../services/utils';

export const formatStrategyName = (sub: any) => {
  if (!sub?.strategy) return 'Unknown Strategy';
  const { strategyId } = sub.strategy;
  if (strategyId === 'legacy') {
    return [
      'Repay',
      sub.specific.boostEnabled && 'Boost',
    ].filter(s => !!s).join(' & ');
  }
  if (strategyId === 'leverage-management') {
    return [
      sub.specific.repayEnabled && 'Repay',
      sub.specific.boostEnabled && 'Boost',
    ].filter(s => !!s).join(' & ');
  }
  if (strategyId === 'leverage-management-eoa') {
    return [
      sub.specific.repayEnabled && 'Repay EOA',
      sub.specific.boostEnabled && 'Boost EOA',
    ].filter(s => !!s).join(' & ');
  }
  if (strategyId === 'smart-savings-dsr-supply') {
    return 'Savings Protection (Supply)';
  }
  if (strategyId === 'debt-in-front-repay') {
    return 'Redemption Protection';
  }

  return dashToNormalCase(sub.strategy.strategyId)
    .replace('Eoa', 'EOA');
};
