import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import './Breadcrumbs.scss';
import { capitalize } from '../feed/formattingUtils';

const Breadcrumbs = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const crumbs = useMemo(() => {
    const entries = searchParams.entries();
    const crumbs = [];
    for (let next = entries.next(); !next.done; next = entries.next()) {
      crumbs.push([...next.value, capitalize(next.value[1].replace('__', ' ').replace('-', ' '))]);
    }
    return [['all', 'all', 'All'], ...crumbs];
  }, [searchParams]);

  const handleCrumbClick = (key: string) => {
    const entries = searchParams.entries();
    const params = new URLSearchParams();
    if (key !== 'all') {
      for (let next = entries.next(); !next.done; next = entries.next()) {
        params.append(next.value[0], next.value[1]);
        if (key === next.value[0]) break;
      }
    }
    setSearchParams(params);
  };
  return (
    <div className="breadcrumbs">
      {crumbs.length > 0 && crumbs.map((crumb) => (
        <>
          <span onClick={() => { handleCrumbClick(crumb[0]); }} className="crumb">{crumb[2]}</span>
        </>
      ))}
    </div>
  );
};

export default Breadcrumbs;
