export const capitalize = (text: string): string => text.replace(/./, (l: string) => l.toUpperCase());

const formatProtocol = (protocol: string): string => {
  if (protocol === 'morpho_blue') return 'Morpho Blue';
  if (protocol === 'morpho_aave_v2') return 'Morpho Aave V2';
  if (protocol === 'morpho_aave_v3') return 'Morpho Aave V3';
  if (protocol === 'crv_usd') return 'CurveUSD';
  if (protocol === 'llama_lend') return 'LlamaLend';
  return protocol.replaceAll('_', ' ').replace(/./, (l: string) => l.toUpperCase());
};

const formatAsset = (asset: string) => asset?.replace('WETH', 'ETH');

const getProtocolFromAction = (action: string) => {
  if (!action) return 'Utility';
  if (action.includes('Sell') || action.includes('Buy')) return 'Exchange';
  if (action.includes('DyDx')) return 'DyDx';
  if (action.includes('Mcd')) return 'Maker';
  if (action.includes('Reflexer')) return 'Reflexer';
  if (action.includes('Liquity')) return 'Liquity';
  if (['morpho', 'blue'].every(term => action.toLowerCase().includes(term))) return 'Morpho Blue';
  if (['morpho', 'aave', 'v2'].every(term => action.toLowerCase().includes(term))) return 'Morpho Aave V2';
  if (['morpho', 'aave', 'v3'].every(term => action.toLowerCase().includes(term))) return 'Morpho Aave V3';
  if (action.includes('Aave') && action.includes('V3')) return 'Aave V3';
  if (action.includes('Aave')) return 'Aave V2';
  if (action.includes('Comp') && action.includes('V3')) return 'Compound V3';
  if (action.includes('Comp')) return 'Compound V2';
  if (action.includes('Lido')) return 'Lido';
  if (action.includes('Uni') && action.includes('V3')) return 'Uniswap V3';
  if (action.includes('Uni')) return 'Uniswap V2';
  if (action.includes('Yearn')) return 'Yearn';
  if (action.includes('Balancer')) return 'Balancer';
  if (action.includes('MStable')) return 'MStable';
  if (action.toLowerCase().includes('convex')) return 'Convex';
  return 'Utility';
};

const getUsedProtocolsInRecipe = (actions: Array<string>) => actions.reduce((finalObj: Record<string, number>, currAction: string) => {
  const protocol = getProtocolFromAction(currAction);
  return { ...finalObj, [protocol]: (finalObj[protocol] || 0) + 1 };
}, {} as Record<string, number>);

export const formatAction = (event: any) => event.action
  .replace('leveraged_create', 'create')
  .replace('claim_swap_supply', 'claim')
  .replace('claim_swap', 'claim')
  .replace('basic_create', 'create')
  .replace('redemption_protection', 'Repay')
  .replace('Custom Recipe', 'Recipe')
  .replace('Loan Shifter', 'Loan Shift')
  .replace('eoa_import', 'Import')
  .replace('instadapp_import', 'Import')
  .replace('limit_order', 'Limit Order')
  .replace('dca', 'DCA')
  .replace('claim_sell', 'Claim & Sell')
  .replace('_', ' ')
  .replace(/\b./g, (l: string) => l.toUpperCase());

export const getTags = (event: any, isAdditionalEvent = false) => {
  const action = formatAction(event);
  const protocol = formatProtocol(event.protocol);
  const tags = [];
  if (event.action === 'redemption_protection') tags.push('Redemption Protection');
  if (event.protocol !== 'recipe_creator') tags.push(protocol);
  else {
    const usedProtocols = getUsedProtocolsInRecipe(event.actions);
    const protocolList = Object.keys(usedProtocols).filter(protocol => protocol !== 'Utility');
    tags.push(...protocolList);
  }
  // if (event.automated) tags.push('Automated');
  if (event.leverageType) tags.push(event.leverageType);
  if (event.isLsv) tags.push('ETH Saver');
  tags.push(capitalize(event.network));
  if (isAdditionalEvent) tags.push('Additional Action');
  return tags;
};
