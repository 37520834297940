import React from 'react';
import PropTypes from 'prop-types';
import ExternalArrowLinkIcon from './ExternalArrowLinkIcon';

const ExternalLink = ({
  href, children, showIcon, color, className,
}) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className={className || ''}>{children} {showIcon && <ExternalArrowLinkIcon color={color} />}</a>
);

ExternalLink.defaultProps = {
  showIcon: false,
  color: 'white',
};
ExternalLink.propTypes = {
  color: PropTypes.string,
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  showIcon: PropTypes.bool,
  className: PropTypes.string,
};

export default ExternalLink;
