import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Feed from '../../feed/Feed';
import Automation from '../../automation/Automation';
import App from './App';
import Contracts from '../../contracts/Contracts';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Feed />,
      },
      {
        path: '/automation',
        element: <Automation />,
      },
      {
        path: '/contracts',
        element: <Contracts />,
      },
    ],
  },
]);

export default router;
