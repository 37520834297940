import React, { useEffect, useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import { useGetSubsQuery } from './automationApi';
import {
  formatAcc, formatEtherScanLink, formatNumber, getColorForRatio, Network, networkIdToLabel, networkLabelToId, numberWithCommas,
} from '../../services/utils';
import './Automation.scss';
import TagIcon from '../decorative/TagIcon/TagIcon';
import { formatStrategyName } from './formattingUtils';
import EntryCard from './EntryCard/EntryCard';
import DataItem from '../decorative/DataItem/DataItem';
import TooltipWrapper from '../decorative/TooltipWrapper/TooltipWrapper';
import ExternalLink from '../decorative/ExternalLink';
import PositionLink from '../decorative/PositionLink';
import Breadcrumbs from './Breadcrumbs';

const Automation = () => {
  const { data: unfilteredData, error, isLoading } = useGetSubsQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterParams = useMemo(() => {
    const network = searchParams.get('network');
    const protocol = searchParams.get('protocol');
    const strategy = searchParams.get('strategy');
    return { network, protocol, strategy };
  }, [searchParams]);
  const data: any[] | undefined = useMemo(() => {
    if (!unfilteredData) return unfilteredData;
    let filteredData = unfilteredData;
    if (filterParams.network) {
      const chainId = networkLabelToId(filterParams.network);
      filteredData = filteredData.filter((row: { chainId: number | undefined; }) => row.chainId === chainId);
    }
    if (filterParams.protocol) {
      filteredData = filteredData.filter((row: { protocol: { id: string | null; }; }) => row.protocol.id === filterParams.protocol);
    }
    if (filterParams.strategy) {
      filteredData
      = filteredData.filter((row: { formattedStrategyId: string }) => row.formattedStrategyId === filterParams.strategy);
    }
    return filteredData;
  }, [unfilteredData, filterParams]);

  const aggregatedData = useMemo(() => {
    const subs = uniqBy(data, 'positionId');
    return {
      activeSubs: subs?.length || 0,
      totalSupplied: subs?.reduce((acc, row) => acc + (row.positionInfo ? +row.positionInfo.suppliedCollateralUsd : 0), 0) || 0,
      totalBorrowed: subs?.reduce((acc, row) => acc + (row.positionInfo ? +row.positionInfo.borrowedUsd : 0), 0) || 0,
      totalSuppliedByNetwork: subs?.reduce((acc, row) => {
        acc[row.chainId] = (acc[row.chainId] || 0) + (row.positionInfo ? +row.positionInfo.suppliedCollateralUsd : 0);
        return acc;
      }, {}),
      totalSuppliedByProtocol: subs?.reduce((acc, row) => {
        acc[row.protocol.id] = (acc[row.protocol.id] || 0) + (row.positionInfo ? +row.positionInfo.suppliedCollateralUsd : 0);
        return acc;
      }, {}),
      filteredSubs: subs,
    };
  }, [data]);

  const formatQuery = (key: string, value: string) => {
    const entries = searchParams.entries();
    const params = new URLSearchParams();
    for (let next = entries.next(); !next.done; next = entries.next()) {
      params.set(next.value[0], next.value[1]);
    }
    params.set(key, value);
    const paramsString = params.toString();
    if (paramsString) return `?${paramsString}`;
    return '';
  };

  return (
    <div className="automation-wrapper feed-wrapper">
      <Helmet>
        <title>Automation Stats | DeFi Saver</title>
      </Helmet>
      <div className="width-container">
        <h1>Automation Stats</h1>
        <Breadcrumbs />
        {error && JSON.stringify(error)}
        {isLoading && <div className="loader-wrapper">Loading...</div>}
        {data && (
          <>
            <div className="aggregated-data">
              {/* @ts-ignore */}
              <DataItem
                label="Total supplied"
                symbol="$"
                value={aggregatedData.totalSupplied}
                symbolAfter={false}
                decimals={0}
              />
              {/* @ts-ignore */}
              <DataItem
                label="Total borrowed"
                symbol="$"
                value={aggregatedData.totalBorrowed}
                symbolAfter={false}
                decimals={0}
              />
              {/* @ts-ignore */}
              <DataItem
                label="Subscriptions"
                value={aggregatedData.activeSubs}
                decimals={0}
              />
            </div>

            {!filterParams.network && (
            <> <h2>Networks</h2>
              <div className="data-grid">
                {Object.entries(groupBy(aggregatedData.filteredSubs, 'chainId'))
                  .map(([chainId, subs]) => (
                    <EntryCard
                      title={networkIdToLabel(+chainId as Network)}
                      colorScheme={networkIdToLabel(+chainId as Network).toLowerCase()}
                      to={formatQuery('network', networkIdToLabel(+chainId as Network).toLowerCase())}
                      type="large"
                    >
                      <div className="data-row"><span>Subscribers:</span> <span>{subs.length}</span></div>
                      <div className="data-row"><span>USD Total:</span> <span>${numberWithCommas(aggregatedData.totalSuppliedByNetwork[chainId], 0)}</span></div>
                    </EntryCard>
                  ))}
              </div>
            </>
            )}

            { !filterParams.protocol && (
            <><h2>Protocols</h2>
              <div className="data-grid">
                {Object.entries(groupBy(aggregatedData.filteredSubs, 'protocol.id'))
                  .sort((a, b) => ((b[1] as any).length - (a[1] as any).length))
                  .map(([protocol, subs]) => (
                    <EntryCard
                      title={subs[0].protocol.name.includes('Morpho') ? 'Morpho' : subs[0].protocol.fullName}
                      subtitle={subs[0].protocol.name.includes('Morpho') ? subs[0].protocol.fullName.replace('Morpho-', '') : ''}
                      colorScheme={protocol}
                      to={formatQuery('protocol', protocol)}
                      type="large"
                    >
                      <div className="data-row"><span>Subscribers:</span> <span>{subs.length}</span></div>
                      <div className="data-row"><span>USD Total:</span> <span>${numberWithCommas(aggregatedData.totalSuppliedByProtocol[protocol], 0)}</span></div>
                    </EntryCard>
                  ))}
              </div>
            </>
            )}

            { !filterParams.strategy && (
            <><h2>Strategies</h2>
              <div className="data-grid">
                {Object.entries(groupBy(data, 'formattedStrategyName'))
                  .sort((a, b) => ((b[1] as any).length - (a[1] as any).length))
                  .map(([strategyId, subs]) => (
                    <EntryCard
                      title={subs[0].formattedStrategyName}
                      to={formatQuery('strategy', subs[0].formattedStrategyId)}
                    >
                      <div className="data-row"><span>Subscribers:</span> <span>{subs.length}</span></div>
                    </EntryCard>
                  ))}
              </div>
            </>
            )}
            <h2>Subscriptions</h2>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Strategy</th>
                    {/* <th>Address</th> */}
                    <th>Supplied</th>
                    <th>Borrowed</th>
                    <th>Ratio</th>
                    <th>Protocol</th>
                    <th>Network</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {data.map(row => (
                    <tr key={row.key}>
                      <td>
                        <div className="title">{formatStrategyName(row)}</div>
                        <div className="subtitle">
                          <PositionLink
                            positionId={row.positionInfo.cdpId || row.positionInfo.bondId || row.owner}
                            protocol={row.protocol.slug}
                            network={row.chainId}
                          >
                            {row.positionInfo.cdpId
                              ? `CDP #${row.positionInfo.cdpId}`
                              : row.positionInfo.bondId
                                ? `Bond #${row.positionInfo.bondId}`
                                : `Smart Wallet ${formatAcc(row.owner)}`}
                          </PositionLink>
                        </div>
                        {/* {row.positionInfo && ( */}
                        {/*  <div className="subtitle"> */}
                        {/*    Coll: ${numberWithCommas(row.positionInfo.suppliedCollateralUsd, 0)} <br/> */}
                        {/*    {row.positionInfo.borrowedUsd > 0 && (<>Debt: ${numberWithCommas(row.positionInfo.borrowedUsd, 0)}<br/></>)} */}
                        {/*    {row.positionInfo.ratio > 0 &&  */}
                        {/*    (<>Ratio: {formatNumber(row.positionInfo.ratio)}% / {formatNumber(`${(row.positionInfo.liqRatio || 1) * 100}`)}% <br/></>)} */}
                        {/*  </div> */}
                        {/* )} */}
                        {/* {row.specific && <div className="subtitle"><pre>{JSON.stringify(row.specific, null, 2)}</pre></div>} */}
                        {/* {row.strategyData?.decoded && <div className="subtitle"><pre>{JSON.stringify(row.strategyData.decoded, null, 2)}</pre></div>} */}
                      </td>
                      {/* <td>{formatAcc(row.owner)}</td> */}
                      <td>
                        <span className="mobile-only">Supplied: </span>
                        ${numberWithCommas(row.positionInfo?.suppliedCollateralUsd, 0)}
                      </td>
                      <td className={row.positionInfo?.borrowedUsd > 0 ? '' : 'desktop-only'}>
                        <span className="mobile-only">Borrowed: </span>
                        {row.positionInfo?.borrowedUsd > 0 && (<>${numberWithCommas(row.positionInfo.borrowedUsd, 0)}</>)}
                      </td>
                      <td className={row.positionInfo?.borrowedUsd > 0 ? '' : 'desktop-only'}>
                        {row.positionInfo?.ratio > 0 && (
                        <TooltipWrapper title={`Liquidation at ${formatNumber(`${(row.positionInfo.liqRatio || 1) * 100}`)}%`}>
                          <span className="mobile-only">Ratio: </span>
                          <span
                            style={{ color: getColorForRatio(row.positionInfo.ratio, (row.positionInfo.liqRatio || 1) * 100) }}
                          >
                            {formatNumber(row.positionInfo.ratio)}%<br />
                          </span>
                        </TooltipWrapper>
                        )}
                      </td>
                      <td>
                        <span className="tag"><TagIcon tag={row.protocol.id} size={24} />{row.protocol.fullName}</span>
                      </td>
                      <td>
                        <span className="tag"><TagIcon tag={networkIdToLabel(row.chainId)} size={24} />{networkIdToLabel(row.chainId)}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Automation;
