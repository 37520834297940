import Dec from 'decimal.js';

export type Network = 1 | 10 | 42161;

export const BLOCK_EXPLORER_URL = {
  1: 'https://etherscan.io',
  10: 'https://optimistic.etherscan.io',
  42161: 'https://arbiscan.io',
  mainnet: 'https://etherscan.io',
  ethereum: 'https://etherscan.io',
  optimism: 'https://optimistic.etherscan.io',
  arbitrum: 'https://arbiscan.io',
  base: 'https://basescan.org',
};

export const networkIdToLabel = (networkId: Network) => ({
  1: 'Ethereum',
  10: 'Optimism',
  42161: 'Arbitrum',
  8453: 'Base',
})[networkId];
export const networkLabelToId = (networkLabel: string): (number | undefined) => ({
  mainnet: 1,
  ethereum: 1,
  optimism: 10,
  arbitrum: 42161,
  base: 8453,
})[networkLabel.toLowerCase()];

export const formatEtherScanLink = (hash: string, type = 'tx', chainId: Network = 1) => `${BLOCK_EXPLORER_URL[chainId]}/${type}/${hash}`;

export const formatDeFiExploreLink = (hash: string, type = 'tx') => `https://defiexplore.com/${type}/${hash}`;

export const parseTimestamp = (timestamp: any, unix = false) => {
  const a = unix
    ? new Date(1000 * timestamp)
    : new Date(timestamp);

  const year = a.getFullYear();
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = monthNames[a.getMonth()];
  const date = a.getDate();
  const hour = a.getHours() < 10 ? `0${a.getHours()}` : a.getHours();
  const min = a.getMinutes() < 10 ? `0${a.getMinutes()}` : a.getMinutes();

  return `${month} ${date}. ${year}, ${hour}:${min}`;
};

export const parseTimeDelta = (timestamp: string, roundSeconds = true) => {
  const seconds = Math.floor((Date.now() - new Date(timestamp).valueOf()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(seconds / 3600);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (months > 1) return `${months} months ago`;
  if (months) return '1 month ago';
  if (days > 1) return `${days} days ago`;
  if (days) return '1 day ago';
  if (hours > 1) return `${hours}h ago`;
  if (hours) return '1h ago';
  if (minutes > 1) return `${minutes}m ago`;
  if (minutes) return '1m ago';
  if (roundSeconds) return 'just now';
  if (seconds > 1) return `${seconds}s ago`;
  return '1 second ago';
};
export const formatTimeLength = (lengthInSeconds: number) => {
  if (+lengthInSeconds === 0) return 'none';

  const days = Math.floor(lengthInSeconds / 24 / 60 / 60);
  const hours = Math.floor((lengthInSeconds % (24 * 60 * 60)) / 60 / 60);
  const minutes = Math.floor((lengthInSeconds % (60 * 60)) / 60);
  const seconds = Math.floor(lengthInSeconds % 60);

  const parts = [];

  if (days > 1) parts.push(`${days} days`);
  else if (days) parts.push('1 day');
  if (hours > 1) parts.push(`${hours} hours`);
  else if (hours) parts.push('1 hour');
  if (minutes > 1) parts.push(`${minutes} minutes`);
  else if (minutes) parts.push('1 minute');
  if (seconds > 1) parts.push(`${seconds}s`);
  else if (seconds) parts.push('1 second');

  return parts.join(', ');
};

export const formatAcc = (account: string, start = 6, end = 4) => `${account.substring(0, start)}...${account.substr(account.length - end)}`;

export const numberWithCommas = (x: string, decimals = 8, removeTrailingZeroes = true) => {
  if (!Number.isFinite(parseFloat(x))) return '0';
  if (parseFloat(x).toString() === '0') return '0';

  const parts = new Dec(x).toFixed(decimals).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  let formatted = parts.join('.');
  if (removeTrailingZeroes) {
    formatted = formatted
      .replace(/(\.\d*[1-9])0*$/, '$1') // 123.1200 -> 123.12
      .replace(/\.0*$/, '') // 123.00 -> 123
      .replace(/^0.0+$/, '0'); // 0.00 -> 0
  }
  return formatted;
};

export const formatNumber = (_num: string, decimals = 2, removeTrailingZeroes = false) => {
  try {
    if (!Number.isFinite(parseFloat(_num))) return '0';
    const sign = parseFloat(_num) < 0 ? '-' : '';
    const num = Math.abs(parseFloat(_num));
    if (num < 10000) return numberWithCommas(_num, decimals, removeTrailingZeroes);
    const si = [
      { value: 1, symbol: '' },
      { value: 1E3, symbol: 'k' },
      { value: 1E6, symbol: 'M' },
      { value: 1E9, symbol: 'B' },
      { value: 1E12, symbol: 'T' },
      { value: 1E15, symbol: 'P' },
      { value: 1E18, symbol: 'E' },
    ];
    let i;
    for (i = si.length - 1; i > 0; i -= 1) {
      if (num >= si[i].value) {
        break;
      }
    }
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    return sign + new Dec(num / si[i].value).toFixed(decimals).replace(rx, '$1') + si[i].symbol;
  } catch (e) {
    console.error(e);
    return _num.toString();
  }
};

export const dashToNormalCase = (input: string) => input
  .replace(/[a-z]/, (letter) => letter.toUpperCase()) // first letter
  .replace(/-([a-z])/ig, (all, letter) => ` ${letter.toUpperCase()}`); // all following letters

export const camelToNormalCase = (input: string) => input.replace(/([A-Z])/g, (all, letter) => ` ${letter}`);

export const defined = (variable: any) => typeof variable !== 'undefined';

/**
 * formatNumber but returns ∞ instead of 0 for Infinity
 */
export const formatNumberWithInf = (_num: string, ...params: any[]) => {
  if (!Number.isFinite(parseFloat(_num))) return '∞';
  return numberWithCommas(_num, ...params);
};

export const pickHex = (color1: number[], color2: number[], weight: number) => {
  const w = (weight * 2) - 1;
  const w1 = (w + 1) / 2;
  const w2 = 1 - w1;
  return [Math.round(color1[0] * w1 + color2[0] * w2), Math.round(color1[1] * w1 + color2[1] * w2), Math.round(color1[2] * w1 + color2[2] * w2)]; // eslint-disable-line
};

export const getRainbowSliderValColor = (val: number, gradients: any[], sliderWidth: number) => {
  let colorRange: any[] = [];

  gradients.forEach((value, index) => {
    if (val <= value[0] && (colorRange.length === 0)) {
      colorRange = [index - 1, index];
    }
  });

  // Get the two closest colors
  const firstColor = gradients[colorRange[0]][1];
  const secondColor = gradients[colorRange[1]][1];

  // Calculate ratio between the two closest colors
  const firstColorX = sliderWidth * (gradients[colorRange[0]][0] / 100);
  const secondColorX = (sliderWidth * (gradients[colorRange[1]][0] / 100)) - firstColorX;
  const sliderX = (sliderWidth * (val / 100)) - firstColorX;
  const ratio = sliderX / secondColorX;

  // Get the color with pickHex(thx, less.js's mix function!)
  return pickHex(secondColor, firstColor, ratio);
};

export const getColorForRatio = (_ratio: any, _liqRatio: any) => {
  let ratio = _ratio;
  let liqRatio = _liqRatio;
  if (Number.isNaN(ratio)) ratio = 0;
  if (Number.isNaN(liqRatio)) liqRatio = 0;
  if (parseFloat(ratio) === 0) ratio = Infinity;
  if (ratio < liqRatio) ratio = liqRatio;
  // if (_ratio > 450) ratio = 250;

  let val = 1 + ((ratio - liqRatio) / (100 / 99));

  if (val > 100) val = 100;

  const RATIO_COLORS = [
    [0, [235, 87, 87]],
    [49, [242, 153, 74]],
    [100, [55, 176, 110]],
  ];

  const rbgArray = getRainbowSliderValColor(val, RATIO_COLORS, 250);
  return `rgb(${rbgArray.join(',')})`;
};
