import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import clientConfig from '../../config/clientConfig';

export const contractsApi = createApi({
  reducerPath: 'contracts',
  baseQuery: fetchBaseQuery({ baseUrl: clientConfig.apiBase }),
  endpoints: (builder) => ({
    getEvents: builder.query<Array<any>, void>({
      query: () => '/contracts/updates',
      transformResponse(response: Record<string, Array<any>>): Array<any> {
        return Object.values(response)
          .flat()
          .sort((a, b) => b.timestamp - a.timestamp);
      },
    }),
  }),
});

export const { useGetEventsQuery } = contractsApi;

