import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import logo from './logo.svg';
import './Header.scss';

const Header = () => (
  <div className="header-wrapper">
    <div className="width-container">
      <div className="header-inner">
        <div className="logo" />
        <nav>
          <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>Feed</NavLink>
          <NavLink to="/automation" className={({ isActive }) => (isActive ? 'active' : '')}>Automation</NavLink>
          <NavLink to="/contracts" className={({ isActive }) => (isActive ? 'active' : '')}>Contracts</NavLink>
        </nav>
      </div>
    </div>
  </div>
);

Header.propTypes = {};

export default Header;
