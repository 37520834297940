import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { feedApi } from '../features/feed/feedApi';
import { automationApi } from '../features/automation/automationApi';
import { contractsApi } from '../features/contracts/contractsApi';

export const store = configureStore({
  reducer: {
    [feedApi.reducerPath]: feedApi.reducer,
    [automationApi.reducerPath]: automationApi.reducer,
    [contractsApi.reducerPath]: contractsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(feedApi.middleware)
    .concat(automationApi.middleware)
    .concat(contractsApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
