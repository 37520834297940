import React from 'react';
import PropTypes from 'prop-types';

export const CopyIcon = ({ copied, color }) => {
  if (copied) {
    return (
      <svg className="copy-icon" width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 8L6 13L18 1" stroke={color} strokeWidth="2" />
      </svg>
    );
  }
  return (
    <svg className="copy-icon" width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.6783 3.85869H6.06355C4.84581 3.85869 3.85863 4.84587 3.85863 6.06361V15.9858C3.85863 17.2035 4.84581 18.1907 6.06355 18.1907H12.6783C13.8961 18.1907 14.8833 17.2035 14.8833 15.9858V6.06361C14.8833 4.84587 13.8961 3.85869 12.6783 3.85869ZM6.06355 2.75623C4.23693 2.75623 2.75616 4.23699 2.75616 6.06361V15.9858C2.75616 17.8124 4.23693 19.2932 6.06355 19.2932H12.6783C14.505 19.2932 15.9857 17.8124 15.9857 15.9858V6.06361C15.9857 4.23699 14.505 2.75623 12.6783 2.75623H6.06355Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 3.85862C0 1.72756 1.72756 0 3.85862 0H10.4734V1.10246H3.85862C2.33644 1.10246 1.10246 2.33644 1.10246 3.85862V12.6783H0V3.85862Z" fill={color} />
    </svg>
  );
};


CopyIcon.defaultProps = {
  color: 'white',
  copied: false,
};

CopyIcon.propTypes = {
  copied: PropTypes.bool,
  color: PropTypes.string,
};
