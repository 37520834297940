import React from 'react';
import PropTypes from 'prop-types';
import ExternalArrowLinkIcon from './ExternalArrowLinkIcon';
import { formatEtherScanLink } from '../../services/utils.ts';
import ExternalLink from './ExternalLink';

const PositionLink = ({
  positionId, protocol, network, children, ...rest
}) => {
  let link = formatEtherScanLink(positionId, 'address', network);
  if (protocol.includes('maker')) link = `https://maker.defiexplore.com/cdp/${positionId}`;
  if (protocol === 'liquity') link = `https://liquity.defiexplore.com/address/${positionId}`;
  if (protocol === 'chicken-bonds') link = `https://chickenbonds.defiexplore.com/bond/${positionId}`;
  return (
    <ExternalLink href={link} {...rest}>{children}</ExternalLink>
  );
};

PositionLink.propTypes = {
  positionId: PropTypes.string.isRequired,
  protocol: PropTypes.string.isRequired,
  network: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default PositionLink;
