import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { numberWithCommas } from '../../../services/utils.ts';
import { CopyIcon } from './TooltipIcons';
import './TooltipWrapper.scss';

const TooltipWrapper = ({
  title, disabled, children, copy, useText,
}) => {
  const [copied, setIsCopied] = useState(false);
  const handleCopyClick = () => {
    setTimeout(() => setIsCopied(true), 250);
    setTimeout(() => setIsCopied(false), 1400);
  };

  const titleFormat = (
    <span className={`tooltip-title-format ${copied ? 'copied' : ''}`}>
      {copy && (<CopyIcon copied={copied} />)}
      { useText || Number.isNaN(Number.parseFloat(title)) ? title : numberWithCommas(title, 18, true) }
    </span>
  );

  return (
    <Tooltip hideOnClick={false} html={titleFormat} disabled={disabled} duration={100}>
      {
        copy
          ? (<CopyToClipboard text={title} onCopy={handleCopyClick}><span>{children}</span></CopyToClipboard>)
          : (<span>{children}</span>)
      }
    </Tooltip>
  );
};

TooltipWrapper.defaultProps = {
  disabled: false,
  useText: false,
  copy: false,
};

TooltipWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
  disabled: PropTypes.bool,
  copy: PropTypes.bool,
  useText: PropTypes.bool,
};

export default TooltipWrapper;
