import React from 'react';
import { Link } from 'react-router-dom';
import './EntryCard.scss';
import TagIcon from '../../decorative/TagIcon/TagIcon';

const EntryCard = ({
  type = '', colorScheme = '', title, children, to, subtitle,
}: {
  type?: '' | 'large',
  colorScheme?: string,
  title: string,
  children: any,
  to?: string,
  subtitle?: string
}) => (
  <div className={`entry-card-wrapper ${type} ${colorScheme}`}>
    <h3>

      {type === 'large' && <TagIcon size={32} tag={colorScheme} />}
      {title}
      { subtitle && <span className="subtitle">{subtitle}</span>}
    </h3>
    <div>
      {children}
    </div>
    {to && (
    <Link className="button transparent" to={to}>
      Explore
      <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 8 }}>
        <path d="M0.964355 6.33161H15.9644M15.9644 6.33161L10.3394 0.974487M15.9644 6.33161L10.3394 11.6887" stroke="white" strokeWidth="1.2" strokeLinecap="round" />
      </svg>
    </Link>
    )}
  </div>
);


export default EntryCard;
