import React from 'react';
import './App.scss';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function App() {
  return (
    <div className="app">
      <Helmet>
        <meta name="theme-color" content="#20292F" />
      </Helmet>
      <Header />
      <div className="page-content-wrapper">
        <Outlet />
      </div>
      <Footer />
      <ScrollRestoration />
    </div>
  );
}

export default App;
