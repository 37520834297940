import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import clientConfig from '../../config/clientConfig';

export type FilterParams = {
  protocols?: string[];
  network?: string;
  actions?: string[];
  networks?: string[];
  leverageType?: string;
  leverageAsset?: string;
};

// Define a service using a base URL and expected endpoints
export const feedApi = createApi({
  reducerPath: 'feed',
  baseQuery: fetchBaseQuery({ baseUrl: clientConfig.apiBase }),
  endpoints: (builder) => ({
    getFeedData: builder.query<Array<any>, any>({
      query: (filters: FilterParams) => {
        const params = new URLSearchParams();
        if (filters.protocols?.length) params.set('protocols', filters.protocols.join(','));
        if (filters.networks?.length) params.set('networks', filters.networks.join(','));
        if (filters.leverageType) params.set('leverageType', filters.leverageType);
        if (filters.actions?.length) params.set('actions', filters.actions.join(','));
        return `feed?${params.toString()}`;
      },
    }),
  }),
});

export const { useGetFeedDataQuery } = feedApi;

