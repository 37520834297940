import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { FilterParams } from './feedApi';

export const useFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params: FilterParams = useMemo((): FilterParams => ({
    protocols: searchParams.get('protocols')?.split(',') || [],
    leverageType: searchParams.get('leverageType') || '',
    leverageAsset: searchParams.get('leverageAsset') || '',
    actions: searchParams.get('actions')?.split(',') || [],
    networks: searchParams.get('networks')?.split(',') || [],
  }), [searchParams]);

  const setParams = (params: FilterParams) => {
    const urlParams: any = {};
    if (params.protocols && params.protocols?.length) urlParams.protocols = typeof params.protocols === 'object' ? params.protocols.join(',') : params.protocols;
    if (params.leverageType && params.leverageType !== 'all') urlParams.leverageType = params.leverageType;
    if (params.actions && params.actions?.length) urlParams.actions = typeof params.actions === 'object' ? params.actions.join(',') : params.actions;
    if (params.networks && params.networks?.length) urlParams.networks = typeof params.networks === 'object' ? params.networks.join(',') : params.networks;
    setSearchParams(urlParams);
  };

  const setParam = useCallback((name: string, value: string): void => {
    setParams({
      ...params,
      [name]: value,
    });
  }, [params]);

  return [params, setParam] as const;
};
