import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import clientConfig from '../../config/clientConfig';
import { formatStrategyName } from './formattingUtils';

// Define a service using a base URL and expected endpoints
export const automationApi: any = createApi({
  reducerPath: 'automation',
  baseQuery: fetchBaseQuery({ baseUrl: clientConfig.apiBase }),
  endpoints: (builder) => ({
    getSubs: builder.query<Array<any>, void>({
      query: () => 'automation/subs/',
      transformResponse: (response: any[]): any => response
        .map((sub: any) => ({
          ...sub,
          formattedStrategyName: formatStrategyName(sub),
          formattedStrategyId: sub.strategy.strategyId === 'legacy' ? 'leverage-management' : sub.strategy.strategyId,
          key: sub.subId
            ? `${sub.chainId}-${sub.subId}`
            : `${sub.chainId}-${sub.owner}-${sub.protocol.slug}-${sub.specific.cdpId}`,
        }))
        .sort((a: any, b: any) => b.positionInfo.suppliedCollateralUsd - a.positionInfo.suppliedCollateralUsd,
          // if (a.suppliedCollateralUsd < b.suppliedCollateralUsd) return -1;
          // if (a.suppliedCollateralUsd > b.suppliedCollateralUsd) return 1;
          // return 0;
        ),
    }),
  }),
});

export const { useGetSubsQuery } = automationApi;

